import React from 'react'
import { Sound } from './sound'
import Typearea from './typearea';

function home() {
  return (
    <div>
      <Sound/>
      <Typearea/>
    </div>
  )
}

export default home
